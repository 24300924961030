window.$ = window.jQuery = require('jquery');
require('jquery-viewport-checker');
require('bootstrap');
require('simplelightbox');
require('./rwd-checker');
require('./dropdown-menu');
require('./mobile-menu');
require('./leaflet/leaflet');
require('./form-animation');


$(function(){
    require('./animations');
    require('./counter');
    require('./sticky-header');
});

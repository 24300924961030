import counterUp from 'counterup2'

$('.counter-section').viewportChecker({
    callbackFunction: function(){
        startCount('.counter');
    },
    offset: 100
});

function startCount(selector){
    const elems = document.querySelectorAll(selector);

    elems.forEach(el => {
        counterUp(el, {
            duration: 1500,
            delay: 16
        });
    });
}